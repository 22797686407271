import React from 'react';
import Header from './header';
import Footer from './footer';
import { ToastContainer } from 'react-toastify';

const CookiePolicy = () => {
  return (
    <>
    <Header/>
     <div className='policy'>
     <div className="heading" >
      <h1  style={{ paddingTop: "90px" }}>Cookie Policy</h1>
      </div>
      <p className="policy-date" ><strong>Effective Date:</strong> 24-Apr-2024 <br></br>
      <strong>Last Updated:</strong> 24-Apr-2024</p>
      
      <u><h3 style={{ paddingTop: "20px" }}>What are cookies?</h3></u>
      <p>This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e., the information we collect using cookies and how that information is used, and how to manage the cookie settings.</p>
      
      <p>Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</p>
      
      <u><h3 style={{ paddingTop: "20px" }}>How do we use cookies?</h3></u>
      <p>As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.</p>
      
      <p>The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.</p>
      
      <u><h3 style={{ paddingTop: "20px" }}>Types of Cookies we use</h3></u>
      
        <strong>1P_JAR:</strong> This cookie is set by Google and is used for advertising purposes.
      
      
      <u><h3 style={{paddingTop:'30px'}}>Cookie Management</h3></u>
      <p>You can manage your cookie preferences through your browser settings. Most browsers allow you to control cookies through their settings preferences. However, please note that blocking cookies may impact your experience on our website.</p>
      
      <u><h3 style={{ paddingTop: "20px" }}>Browser Support Documents</h3></u>
      <ul style={{padding: 0,
            margin: 0}}>
        <li>Chrome: <a href="https://support.google.com/accounts/answer/32050" target="_blank">https://support.google.com/accounts/answer/32050</a></li>
        <li>Safari: <a href="https://support.apple.com/en-in/guide/safari/sfri11471/mac" target="_blank">https://support.apple.com/en-in/guide/safari/sfri11471/mac</a></li>
        <li style={{whiteSpace:'nowrap'}}>Firefox: <a style={{fontSize: '10'}} href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US" target="_blank">
          <span style={{fontSize:"10"}}>https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US</span></a></li>
        <li style={{textAlign:'justify',whiteSpace:'nowrap'}}>Internet Explorer: <a style={{fontSize: '10'}} 
         href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc" target="_blank">
          <span style={{fontSize:"10"}}>https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc</span></a></li>
      </ul><br></br>
      <p>If you are using any other web browser, please visit your browser’s official support documents.</p>
    </div>
    <Footer/>
    <ToastContainer/>
    </>
   
  );
}

export default CookiePolicy;
