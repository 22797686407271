import arrowRight from "../components/images/arrow-right.svg";
import bannerImg1 from "../components/images/banner-img-1.png";
import Managers from "../components/images/Managers.jpg";
import Employee from "../components/images/employees.jpg";
import BookEmployess from "../components/images/Book-Employees.jpg";
import InboundBooking from "../components/images/Inbound-Bookings.jpg";
import OutboundBooking from "../components/images/OUTBOUND-BOOKINGS.jpg";
import mapArea from "../components/images/map-area.svg";
import arrowPath from "../components/images/arrow-path.svg";
import image1 from "../components/images/cwfm-img-1.png";
import image2 from "../components/images/cwfm-img-2.png";
import image3 from "../components/images/cwfm-img-3.png";
import icon1 from "../components/images/icons/icon-1.svg";
import icon2 from "../components/images/icons/icon-2.svg";
import icon3 from "../components/images/icons/icon-3.svg";
import icon4 from "../components/images/icons/icon-4.svg";
import icon5 from "../components/images/icons/icon-5.svg";
import linkedin from "../components/images/icons/linkedin.svg";
import twitter from "../components/images/icons/twitter.svg";
import facebook from "../components/images/icons/facebook.svg";
import rightArrowBlue from "../components/images/icons/right-arrow-blue.svg";
import lineBg from "../components/images/line-bg.png";
import beginbg from "../components/images/begin-bg.png";
import bluebg from "../components/images/blue-bg.png";
import logo from "../components/images/logo.svg";
import Slider from "react-slick";
import Header from "./header";
import PrivacyPolicy from "./privacyPolicy"
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import Footer from "./footer";

const LandingPage = () => {
  const d = new Date();
let year = d.getFullYear();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    company: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleGetStartedButtonClick = () => {
    // Replace 'your-link-here' with the actual link you want to open
    window.location.href = process.env.REACT_APP_HOME_URL;
  };
 
  const handlePrivacyPolicy= () => {
    // window.location.href = 'http://localhost:3000/privacypolicy'
    window.open('http://localhost:3000/privacypolicy', '_blank');
    
  };


  const settings = {
    infinite: true,
    dots: true,
    fade: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  const validateEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check for mandatory fields other than lastName

    // Validate email
    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);

      return;
    }

    // API endpoint URL
    const apiUrl = process.env.REACT_APP_BASE_URL + "contact-Us";
 

    try {
      // Make a POST request to the API using Axios
      const response = await axios.post(apiUrl, formData);
      console.log(response);
      // Handle the response as needed
      toast.info("Message sended succesfully !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      // Optionally, reset the form after successful submission
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        company: "",
        message: "",
      });
      setLoading(false);
    } catch (error) {
      // Handle errors
      toast.error("Something went wrong !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };
  return (
    <>
      <Header />
      <section
        className="banner-sc"
        style={{ backgroundImage: `url(${lineBg})` }}
      >
        <div className="row content-row">
          <div className="col-md-6">
            <div className="banner-contents">
              <h1>Transforming the way companies manage human resources</h1>

              <ul className="common-list">
                <li>
                  Streamline your workforce collaboration with ScaleUpHQ, where
                  efficiency meets simplicity
                </li>
                <li>
                  Innovative employee booking solution, empowering companies to
                  seamlessly share workforce capabilities.
                </li>
              </ul>
              <button
                className="btn btn-primary text-white btn-primary-group"
                onClick={handleGetStartedButtonClick}
              >
                Get Started
                <img src={arrowRight} alt="" />
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="banner-img-box">
              <img src={bannerImg1} className="w-100" />
            </div>
          </div>
        </div>
      </section>

      <section className="slider-sc">
        <div className="container">
          <div className="main-screen-outer">
            <h3>Our Main Screens</h3>
            <div className="main-screen">
              <Slider {...settings}>
                <div className="screen-items">
                  <img src={Managers} alt="Managers" />
                </div>
                <div className="screen-items">
                  <img src={Employee} alt="Employee" />
                </div>
                <div className="screen-items">
                  <img src={BookEmployess} alt="BookEmployess" />
                </div>
                <div className="screen-items">
                  <img src={InboundBooking} alt="InboundBooking" />
                </div>
                <div className="screen-items">
                  <img src={OutboundBooking} alt="OutboundBooking" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section
        className="streamline-sc"
        style={{ backgroundImage: `url(${bluebg})` }}
      >
        <div className="container">
          <h2 className="main-ttl text-white text-center">
            Streamlined Workforce Oversight with ScaleUpHQ
          </h2>

          <div className="row stream-row justify-content-center">
            <div className="col my-2">
              <div className="stream-box">
                <div>
                  <div className="icon-box">
                    <img src={icon1} alt="" />
                  </div>
                  <h5>Managers</h5>
                </div>
              </div>
            </div>
            <div className="col my-2">
              <div className="stream-box">
                <div>
                  <div className="icon-box">
                    <img src={icon2} alt="" />
                  </div>
                  <h5>Employees</h5>
                </div>
              </div>
            </div>
            <div className="col my-2">
              <div className="stream-box">
                <div>
                  <div className="icon-box">
                    <img src={icon3} alt="" />
                  </div>
                  <h5>Book Employees</h5>
                </div>
              </div>
            </div>
            <div className="col my-2">
              <div className="stream-box">
                <div>
                  <div className="icon-box">
                    <img src={icon4} alt="" />
                  </div>
                  <h5>Inbound Bookings</h5>
                </div>
              </div>
            </div>
            <div className="col my-2">
              <div className="stream-box">
                <div>
                  <div className="icon-box">
                    <img src={icon5} alt="" />
                  </div>
                  <h5>Outbound Bookings</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="go-sc text-center">
            <button
              className="btn btn-light bg-white text-primary btn-primary-group"
              onClick={handleGetStartedButtonClick}
            >
              Get Started <img src={rightArrowBlue} alt="" />
            </button>
          </div>
        </div>
      </section>

      <section className="admin-sc">
        <div className="container admin-container">
          <h2 className="main-ttl text-center">
            Explore how our system empowers managers and administrators
          </h2>

          <div className="row admin-rows g-md-5 align-items-center">
            <div className="col-md-6">
              <div className="designation-image">
                <img src={image1} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="designation-content">
                <h3>Employees Section: A Central Hub for Oversight</h3>
                <h4>
                  Employee Section is a central hub for efficient and strategic
                  workforce management
                </h4>

                <ul className="common-list mb-4">
                  <li>
                    View crucial employee details like Designation, Hourly Rate,
                    and Booking History in a single, comprehensive dashboard.
                  </li>
                  <li>
                    Manage your team effortlessly by adding, editing, and
                    deleting employees as needed.
                  </li>
                  <li>
                    Flexibility to hide unavailable employees from the Booking
                    List, ensuring accurate scheduling.
                  </li>
                </ul>

                <div className="des-button">
                  <button
                    className="btn btn-outline-primary btn-primary-group"
                    onClick={handleGetStartedButtonClick}
                  >
                    Get Started <img src={rightArrowBlue} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row admin-rows g-md-5 align-items-center">
            <div className="col-md-6">
              <div className="designation-content">
                <h3>Booking Section: Make Informed Decisions</h3>
                <h4>Explore a Diverse Talent Pool from Other Companies</h4>

                <ul className="common-list mb-4">
                  <li>
                    Access a comprehensive list of available employees from
                    other companies, including essential details like
                    Designation, Experience, Skills, and Hourly Rate.
                  </li>
                  <li>
                    Streamline your booking decisions with all the information
                    you need at your fingertips.
                  </li>
                </ul>
                <div className="des-button">
                  <button
                    className="btn btn-outline-primary btn-primary-group"
                    onClick={handleGetStartedButtonClick}
                  >
                    Get Started <img src={rightArrowBlue} alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="designation-image">
                <img src={image2} alt="" />
              </div>
            </div>
          </div>

          <div className="row g-md-5 admin-rows align-items-center">
            <div className="col-md-6">
              <div className="designation-image">
                <img src={image3} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="designation-content">
                <h3>Inbound and Outbound Bookings: Total Booking Control</h3>
                <h4>
                  Comprehensive Booking Management with Advanced Controls and
                  Status Tracking
                </h4>

                <ul className="common-list mb-4">
                  <li>Track all incoming and outgoing bookings seamlessly.</li>
                  <li>
                    Monitor the status of your bookings, including approval,
                    pending, and rejection, as well as Statement of Work (SOW)
                    approval status.
                  </li>
                  <li>
                    Options to extend booking durations and perform pre-closure
                    for added flexibility and control.
                  </li>
                </ul>
                <div className="des-button">
                  <button
                    className="btn btn-outline-primary btn-primary-group"
                    onClick={handleGetStartedButtonClick}
                  >
                    Get Started <img src={rightArrowBlue} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="begin-sc">
        <div className="container">
          <div
            className="begin-box"
            style={{ backgroundImage: `url(${beginbg})` }}
          >
            <h3>Let’s Begin</h3>
            <h2>Take charge of your workforce management with ScaleUpHQ</h2>
            <button
              className="btn btn-primary text-white btn-primary-group"
              onClick={handleGetStartedButtonClick}
            >
              Get Started <img src={arrowRight} alt="" />
            </button>
          </div>
        </div>
      </section>

      <section className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="leftbox">
                <img className="footerlogo" src={logo} alt="" />
                <p>
                  Have questions or need assistance with our workforce
                  management solution? Our team is here to help! Feel free to
                  reach out.
                </p>

                <div className="maps">
                  <img src={mapArea} alt="" />
                </div>

                <div className="map-arrow text-center">
                  <img src={arrowPath} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="right-box">
                <h3>
                  Connect with Us!
                  <br />
                  We’d Love to Hear From You
                </h3>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="floating-label-group">
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          required
                        />
                        <label className="floating-label">First Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="floating-label-group">
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        <label className="floating-label">Last Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="floating-label-group">
                        <input
                          type="text"
                          className="form-control"
                          name="phoneNumber"
						  value={formData.phoneNumber}
                          onChange={handleChange}
                        />
                        <label className="floating-label">Phone Number</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="floating-label-group">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <label className="floating-label">Email Address</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="floating-label-group">
                        <input
                          type="text"
                          className="form-control"
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                          required
                        />
                        <label className="floating-label">Company Name</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="floating-label-group">
                        <input
                          type="text"
                          className="form-control"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        />
                        <label className="floating-label">Your Message</label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-primary text-white btn-primary-group"
						disabled={loading}
                      >
                        Send Message 
						
						{!loading ? <img src={arrowRight} alt="" />:
						<ScaleLoader
                          color="#ffffff"
                          height={20}
                          margin={2}
                          radius={2}
                          width={3}
						  style={{marginLeft: '8px',marginTop:"6px"}}
                        />}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <footer className="footer-sc">
        <div className="container">
          <div className="row align-items-center">
          
          <div className="col-md-6">
              <p className="my-1 text-white text-md-start text-center">
                © {year} ScaleUpHQ. All Rights Reserved.
              </p>
            </div>
            <div className="col-md-6">
              <button onClick={handlePrivacyPolicy} class="btn btn-primary text-white">Privacy Policy</button>
             
              <button class="btn btn-primary text-white">Cookie Policy</button>
             
            </div> 
            
          </div>
        </div>
      </footer> */}
      <Footer/>
      <ToastContainer />
    </>
  );
};

export default LandingPage;
