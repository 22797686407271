import './App.css';
import LandingPage from './pages/landingPages';
import './components/scss/styles.scss'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Aos from 'aos';
import { BrowserRouter, Router, Route, Switch, Routes } from 'react-router-dom';
import PrivacyPolicy from "./pages/privacyPolicy"
import CookiePolicy from './pages/cookie';
import Faqs from './pages/faqs';


function App() {

  useEffect(()=>{
    Aos.init();
  },[])
  return (
    <div className="App">
      {/* <LandingPage /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/cookiepolicy" element={<CookiePolicy />} />
          <Route exact path="/faqs" element={<Faqs/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
