import React from "react";
import Header from "./header";
import { ToastContainer } from "react-toastify";
import Footer from "./footer";
import Accordion from "react-bootstrap/Accordion";

const Faqs = () => {
  return (
    <div>
      <Header />

      <div
        className="container"
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <h1 style={{ paddingTop: "80px" }}>Frequently Asked Questions</h1>
        <Accordion style={{ paddingTop: "15px" }}>
          <Accordion.Item eventKey="0">
            <Accordion.Header> What is ScaleUpHQ?</Accordion.Header>
            <Accordion.Body className="accordian-body">
            ScaleUpHQ is a B2B application that functions as an employee booking platform, enabling companies to seamlessly share and book human resources.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Who can register on ScaleUpHQ?</Accordion.Header>
            <Accordion.Body>
              Companies can register directly on ScaleUpHQ to create and oversee
              employee listings, as well as facilitate the booking of employees
              from other companies.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              What role does the Company Admin play on ScaleUpHQ?
            </Accordion.Header>
            <Accordion.Body>
              The Company Admin can create Managers for the company, who have
              the option to create and oversee employee listings, as well as
              facilitate the booking of employees from other companies.
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="3">
            <Accordion.Header>
              What are the main sections in ScaleUpHQ?
            </Accordion.Header>
            <Accordion.Body>
              The main sections in ScaleUpHQ include Managers, Employees, Book
              Employees, Inbound Bookings, Outbound Bookings, and a Dashboard.
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              What functionalities are available in the Employees section?
            </Accordion.Header>
            <Accordion.Body>
              In the Employees section, managers can view essential employee
              details such as Designation, Hourly Rate, Booking History, and
              have the ability to add, edit, or delete employees. They can also
              hide employees from the Booking List if they are unavailable for a
              specific period.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              How can managers track the booking status of employees?
            </Accordion.Header>
            <Accordion.Body>
              Company admins/managers can easily view whether an employee is
              currently booked, booked in the future, or not booked in a single
              view within the Employees section.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              What details are provided in the Book Employees section?
            </Accordion.Header>
            <Accordion.Body>
              The Book Employees section displays employees from other companies
              available for booking, along with crucial details like
              Designation, Experience, Skills, and Hourly Rate.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              How can Managers track bookings on ScaleUpHQ?
            </Accordion.Header>
            <Accordion.Body>
              Managers can view all incoming bookings in the Inbound Bookings
              section and the bookings they have made in the Outbound Bookings
              section. They can monitor booking statuses and Statement of Work
              (SOW) approvals.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              Are there options to modify booking durations on ScaleUpHQ?
            </Accordion.Header>
            <Accordion.Body>
              Yes, managers have the option to extend booking durations as
              needed, and they can also choose to pre-close bookings when
              required.
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="9">
            <Accordion.Header>
              How can managers handle employee availability for bookings?
            </Accordion.Header>
            <Accordion.Body>
              Managers can effectively manage employee availability for bookings
              by hiding them from the Booking List if they are unavailable for a
              specific period.
            </Accordion.Body>
          </Accordion.Item> */}
          {/* <Accordion.Item eventKey="10">
            <Accordion.Header>
              Can managers view historical booking data on ScaleUpHQ?
            </Accordion.Header>
            <Accordion.Body>
              Yes, managers have access to employees' booking history, allowing
              them to track past bookings and make informed decisions for future
              bookings.
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="11">
            <Accordion.Header>
              Is there a feature to track the skills and experience of available
              employees?
            </Accordion.Header>
            <Accordion.Body>
              Yes, the Book Employees section provides details on the skills and
              experience of available employees to assist managers in selecting
              the most suitable candidates for bookings.
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="12">
            <Accordion.Header>
              How are booking approvals and rejections managed on ScaleUpHQ?
            </Accordion.Header>
            <Accordion.Body>
              Managers can view the status of booking approvals, pending
              statuses, and rejections for both incoming and outgoing bookings,
              enabling efficient management of the booking process.
            </Accordion.Body>
          </Accordion.Item> */}
          {/* <Accordion.Item eventKey="13">
            <Accordion.Header>
              Can managers initiate the closure of bookings before the scheduled
              end date?
            </Accordion.Header>
            <Accordion.Body>
              Yes, managers have the option to pre-close bookings if needed,
              providing flexibility in managing booking durations based on
              changing requirements.
            </Accordion.Body>
          </Accordion.Item> */}
          {/* <Accordion.Item eventKey="14" style={{ borderBottom: "none" }}>
            <Accordion.Header>
              How can managers ensure efficient communication with other
              companies for bookings?
            </Accordion.Header>
            <Accordion.Body>
              Managers can use ScaleUpHQ to streamline communication with other
              companies by tracking the status of Statement of Work (SOW)
              approvals, ensuring transparent and effective booking processes.
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
      </div>
      <br>
      </br>
      <br></br>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Faqs;
